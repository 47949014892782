const listConnectAvailable = [
  { hub: 'channelAdvisor', target: 'shopify' },
  { hub: 'channelAdvisor', target: 'woocommerce' },
  { hub: 'channelAdvisor', target: 'magento2' },
  { hub: 'channelAdvisor', target: 'peoplevox' },
  { hub: 'shopify', target: 'channelAdvisor' },
  { hub: 'shopify', target: 'esty' },
  { hub: 'shopify', target: 'tiktok' },
  { hub: 'magento2', target: 'channelAdvisor' },
  { hub: 'bigcommerce', target: 'channelAdvisor' },
  { hub: 'woocommerce', target: 'channelAdvisor' },
  { hub: 'esty', target: 'hypermule' },
  { hub: 'tiktok', target: 'shopify' },
  { hub: 'alphabroder', target: 'channelAdvisor' },
  { hub: 'linnworks', target: 'channelAdvisor' },
  { hub: 'quickbook', target: 'channelAdvisor' },
  { hub: 'prestashop', target: 'channelAdvisor' },
  { hub: 'visualsoft', target: 'channelAdvisor' },
  { hub: 'lightspeed', target: 'channelAdvisor' },
  { hub: 'brightpearl', target: 'channelAdvisor' },
  { hub: 'essendant', target: 'channelAdvisor' },
  { hub: 'odoo', target: 'woocommerce' },
  { hub: 'odoo', target: 'channelAdvisor' },
  { hub: 'xentral', target: 'channelAdvisor' },
  { hub: 'netsuite', target: 'channelAdvisor' },
  { hub: 'netsuite', target: 'shopify' },
  { hub: 'lightspeedX', target: 'channelAdvisor' },
  { hub: 'xero', target: 'channelAdvisor' },
  { hub: 'magento2', target: 'shopify' },
  { hub: 'apprise', target: 'channelAdvisor' },
  { hub: 'xentral', target: 'mebel' },
  { hub: 'mebel', target: 'xentral' },
  { hub: 'portica', target: 'channelAdvisor' },
  { hub: 'esty', target: 'channelAdvisor' },
  { hub: 'activeant', target: 'channelAdvisor' },
  { hub: 'channelAdvisor', target: 'activeant' },
  { hub: 'apprise', target: 'channelAdvisor' },
  { hub: 'aims360', target: 'channelAdvisor' },
  { hub: 'cin7', target: 'channelAdvisor' },
  { hub: 'channelAdvisor', target: 'bigcommerce' },
  { hub: 'sapb1', target: 'channelAdvisor' },
  { hub: 'dynamics365', target: 'channelAdvisor' },
  { hub: 'channelAdvisor', target: 'dynamics365' },
  { hub: 'quickbook', target: 'teapplix' },
  { hub: 'quickbookDesktop', target: 'channelAdvisor' },
  { hub: 'woocommerce', target: 'shopify' },
  { hub: 'channelAdvisor', target: 'convictional' },
  { hub: 'sapb1', target: 'warner' },
  { hub: 'quivo', target: 'channelAdvisor' },
  { hub: 'channelAdvisor', target: 'commercehub' },
  { hub: 'odoo', target: 'linkweld' },
  { hub: 'myflex', target: 'channelAdvisor' },
  { hub: 'dynamicsax', target: 'channelAdvisor' },
  { hub: 'dynamicsfo', target: 'channelAdvisor' },
  { hub: 'sapb1fileexport', target: 'channelAdvisorFileImport' },
  { hub: 'sapb1fileexport', target: 'channelAdvisor' },
  { hub: 'channelAdvisor', target: 'magento2saas' },
  { hub: 'magento2saas', target: 'channelAdvisor' },
]

const listPlatformHub = ['channelAdvisor'];
const listPlatformTarget = ['channelAdvisor'];
listPlatformHub.forEach(platformHub => {
  listPlatformTarget.forEach(platformTarget => {
    const foundConnect = listConnectAvailable.find(item => item.hub === platformHub && item.target === platformTarget);
    if (!foundConnect) {
      listConnectAvailable.push({
        hub: platformHub,
        target: platformTarget,
      });
    }
  });
});

export default listConnectAvailable
